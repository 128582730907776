html, body {
    margin: 0;
    padding: 0;
    height:100%;
    font-family: "Nunito", sans-serif;

    background: #f9fafb;
}
body {
    overflow-y:scroll;
}
#container {
    width: 100%;
    height:100%;
    position: relative;
}
#left {
    background-color: #242527;
    color: #FFF;
    height:100%;
    width: 80px;
    position: fixed;
    z-index: 2;
}


#page {
    background-color: #f9fafb;
    color: #000;
    position: absolute;
    left: 80px;
    right: 0;
    height:100%;
}
#left, #page {
    display: inline-block;
    vertical-align: top;
}

a {
    text-decoration: none;
}

#logo a {
    margin:0;
    padding:20px;
    vertical-align: top;
    display: inline-block;
    transition: background-color 300ms;
}

#logo img {
    width: 38px;
    height: 38px;
    vertical-align: top;
    
}

#logo a:hover {
    background: #000;
}

#menu {
    padding: 0;
    margin: 0;
}

#menu li {
/*    padding: 5px 0;*/
    margin: 0;
    list-style: none;
}

#menu li a {

    display: block;
    position: relative;
    transition: background-color 300ms;
}

#menu li a:hover {
    background: #000;
}

#menu li a div {
    color:#fff;
    font-weight: bold;
    font-size:10px;
    text-align: center;
    padding-bottom:10px;
    width: 72px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin: 0 4px;
    /*display: none;
    background: #000;
    color:#fff;
    padding: 0;
    z-index: 3;
    position: absolute;
    left:52px;
    top:0;
    line-height: 52px;
    border-radius: 0 4px 4px 0;
    font-weight: bold;
    font-size:16px;
    transition: 300ms all ease;
    width: 0;
    opacity: 0;*/
}

#menu li a:hover div {
   /* display: block;
    width:auto;
    padding: 0 14px 0 28px;
    opacity: 1;*/
}

#menu li a.active {
    background: #3a3b3d;
}

#menu li img {
    width: 24px;
    height: 24px;
    vertical-align: top;
    display: inline-block;
    padding:10px 28px 6px 28px;
}

#menu li a:hover {
    background: #000;
}

#topbar {
    background: #fff;
    width: 100%;
    color: #858796;
    text-align: right;
    box-sizing: border-box;
    margin-bottom: 24px;
}

#menu_bottom {
    position:fixed;
    bottom:0;
    border-top:1px solid #5a5a5a;
    padding-bottom:20px;
    padding-top:5px;
}

#topbar {
    background: #242527;
    text-align: right;
}

#topbar #user, #topbar #superadmin{
    display: inline-block;
    font-weight: bold;
    padding: 10px;
    display: inline-block;
    cursor: pointer;
    color:#fff;
    margin-right: 10px;
    white-space: nowrap;
    position: relative;
    
}
#topbar #user:hover, #topbar #superadmin:hover {
     background: #666;
}
/*#topbar #user a {
    color:#fff;
    font-weight: bold;
    padding: 10px;
    display: inline-block;
}
#topbar #user a:hover {
    background: #666;
}*/
#topbar #user ul, #topbar #superadmin ul{
    position: absolute;
    background: #fff;
    right:0;
    padding:0;
    margin:0;
    list-style-type: none;
    top:42px;
    display: none;
    border-right: 1px solid #e0e3e7;
    border-bottom: 1px solid #e0e3e7;
    border-left: 1px solid #e0e3e7;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

#topbar #user:hover ul, #topbar #superadmin:hover ul{
    display: block;
}

#topbar #user a, #topbar #superadmin li{
    color:#000;
    padding:10px;
    display: block;
}
#topbar #user a:hover, #topbar #superadmin li:hover {
    background: #ccc;
}

#topbar #superadmin ul{
    text-align: left;
    max-height: 300px;
    overflow: auto;
}

#topbar #exit {
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    margin-right: 20px;
}
#topbar #exit:hover {
    background: #666;
}
#topbar #exit img {
    width:22px;
    height: 22px;
    padding:10px;
    
    vertical-align: top;
}
/*
#search {
    display: inline-block;
    padding:10px;
}
#user {
    display: inline-block;
    padding:10px;
}
#exit {
    display: inline-block;
    padding:10px;
    cursor: pointer;
    color:#4e73df;
}
#exit:hover {
    background: #4e73df;
    color:#fff;
}
*/
.hide {
    display: none;
}
.login_block {
    width:300px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    color:#000;
    font-size:16px;

    background: #fff;
    border-radius: 10px;
    color:#000;
    margin-bottom:20px;
    border:1px solid #e0e3e7;
    
}

.login_header {
    line-height: 50px;
    font-size: 28px;
    font-weight: bold;
    padding-bottom:22px;
    margin-bottom:22px;
    border-bottom:1px solid #e0e3e7;
    padding:22px;
}

.login_header img {
    width: 50px;
    height: 50px;
    vertical-align: top;
    margin-right: 12px;
}


.login_block .text {
    padding: 0 22px 20px 22px;
    text-align: left;
    color: #637286;
}

.login_block .value {
    padding: 0 22px;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    color: #637286;
}

.login_block .value input{
    margin-bottom: 18px;
    border-radius: 10px;
    padding: 0 20px 0 48px;
    width:100%;
    box-sizing: border-box;
    border:1px solid #e0e3e7;
        line-height: 38px;
}

.login_block .value input:focus {
    outline: none !important;
    border:1px solid #7b61ff;
    box-shadow: 0 0 4px #7b61ff;


}

.login_block .value:last-child{
    padding-bottom:10px;
}

.login_block .submit {
    padding:0 22px;
    margin-bottom: 14px;
}
.login_block .submit input{
    border:1px solid #7b61ff;
    width:100%;

    cursor:pointer;

    color:#fff;
    background: #7b61ff;
    border-radius: 10px;
    padding:10px 20px;
 
    font-size:14px;
    font-weight: bold;
}


.login_block .submit:hover input {
    border:1px solid #7b61ff;
    box-shadow: 0 0 4px #7b61ff;
}

.login_block .value img.icon {
    position: absolute;
    width:16px;
    height: 16px;
    padding:12px;
    margin-left:10px;
}

.login_block .value img.pass {
    display: none;
    position: absolute;
    width:16px;
    height: 16px;
    padding:6px;
    margin:6px;
    right:30px;
    cursor: pointer;
    border-radius: 10px;
}
.login_block .value img.active {
    display: block;
}

.login_block .value img.pass:hover {
    background: #e0e3e7;
}
.login_block .value button{
    margin-bottom: 18px;
    border-radius: 10px;
    width:100%;
    box-sizing: border-box;
    border:1px solid #000;
    background: #fff;
    line-height: 34px;
    font-weight: bold;
    cursor: pointer;
}
.login_block .value button img {
    vertical-align: top;
    height:24px;
    padding:4px 10px 4px 0;
}

.login_block .value button:hover {
    background: #7b61ff;
    border:1px solid #7b61ff;
    box-shadow: 0 0 4px #7b61ff;
    color:#fff;
}

.login_block .error {
    color:#f43f5e;
    font-weight: bold;
    line-height: 24px;
    margin: 20px;
    border:1px solid #fddbe0;
    background: #feecef;
    border-radius: 10px;
    padding: 10px 20px;
}

.login_block .reset {
    margin-bottom:10px;
    display: inline-block; 
}

.login_block a{
    font-weight: 700;
    color: #7b61ff;
    border-bottom: 1px solid transparent;
}

.login_block a:hover {
    border-bottom: 1px solid #7b61ff;
}

.login_bottom {
    text-align: center;
    border-bottom:1px solid #e0e3e7;
    height:1px;
    position: relative;
    margin: 8px 22px 26px 22px;
}

.login_bottom div{
    position: absolute;
    font-size: 12px;
    font-weight: bold;
    color: #697386;
    background: #fff;
    padding: 0 10px;
   
    left:50%;
    transform: translate(-50%,0);
    top:-6px;
}



.container {
    padding: 0 26px 50px;
}
.header {
    line-height: 34px;
    color:#000;
    font-size:28px;
    margin-bottom:24px;
    margin-top:40px;
    font-weight: bold;
    padding: 0 12px;
}

.block_placeholder {
    background: repeating-linear-gradient(to right, #e0e3e7 0%, #3a3b3d 50%, #e0e3e7 100%);
    background-size: 200% auto;
    background-position: 0 100%;
    background-repeat: repeat;
    animation: blockPlaceholder 2s infinite;

    background-size: 200% auto;
    background-position: 0 100%;

    animation-fill-mode: forwards;
    animation-timing-function: linear;

    border-radius: 10px;
    margin:12px;
    border: 1px solid #e0e3e7;
    height:100px;
}
.block{
    background: #fff;
    border-radius: 10px;
    color:#000;
    margin-bottom:20px;
    border:1px solid #e0e3e7;
    margin:12px 12px 24px 12px;
}

.block_row {
    padding:26px;
}

.block_header{
    font-size: 18px;
    line-height: 72px;
    padding: 0 26px;
}

.block_header img.icon {
    vertical-align: top;
    background: #f9fafb;
    width:18px;
    height:18px;
    padding:5px;
    border-radius: 4px;
    margin: 22px 12px 22px 0;
}

.block_right {
    float:right;
    display: none;
}

.block_right .button {
    border-radius: 10px;
    display: inline-block;
    border: 1px solid #697386;
    line-height: 36px;
    margin-left: 8px;
    font-size:14px;
    color:#697386;
    padding:0 14px;
}

.block_right .button:hover {
    color: #7b61ff;
    border:1px solid #7b61ff;
}
.block_right .button img {
    vertical-align: top;
    width:14px;
    height:14px;
    padding:10px 4px 10px 0;

}

.table {
    display: table;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    margin-bottom: 10px;
}
.table.nh {
    margin-top:20px;
}
.table_header{
    display: table-row;
    text-transform: uppercase;
    color:#697386;
    line-height: 30px;
    font-size:13px;
    font-weight: bold;

}

.table.total_money {
    margin-top:20px;
    padding: 0px;
    white-space: nowrap;
}
.table.total_money .table_cell{
    width: 25%;
}
.table.total_money .table_cell:nth-child(2){
    color:#00a93c;
}

.table.total_money .table_cell:nth-child(3){
    color:#bda700;
}

.table.total_money .table_row .table_cell {

}

.table_header .table_cell {
    background-color: #f9fafb;
    
}

.table_cell a{
    color: #493999;
    display: block;
}

.table_cell a:hover {
    color: #00a93c;
}

.table_cell a:hover .small_info{
    color: #00a93c;
}

.table_cell a:hover .small_right{
    color: #00a93c;
}

.table_cell .small_right{
    display: inline-block;
    margin-left:4px;
    font-size:12px;
    color:#697386;
}

.table_cell .small_info{
    font-size:12px;
    color:#697386;
}

.table_header .table_cell:first-child{
    border-radius: 10px 0 0 10px;
}

.table_header .table_cell:last-child{
    border-radius: 0 10px 10px 0;
}

.table_row{
    display: table-row;
    font-size:14px;
}

[relevance=false]:hover {
    opacity:1;

}
[relevance=false]{
   opacity:0.7;
   color:#697386 !important;
}
[relevance=false] a{
   color:#697386 !important;
}

.table_row[hide=true] a{
   color:#697386 !important;
}
.table_row.expired {
    background: #ffe0e0;
}
.table_cell{
    display: table-cell;
    padding: 10px 20px;
}

.table_right {
    text-align: right;
}
.table_more {
    width:32px;
}
.table_row .table_cell {
    border-bottom: 1px solid #eaf0f5;
    color:#697386;
    font-size:14px;
    vertical-align: top;
}

.table_row:last-child .table_cell {
    border-bottom: none;
}

.table_row .table_cell.table_main {
    color:#000;
    font-size:16px;
    maring-left:30px;
    font-weight: bold;
    width: 400px;

}
.table_row .table_cell.table_main a {
    line-height: 20px;
}

.table_row .table_cell.table_main a:hover{
    color: #000;
}


.table_row:hover .table_cell{
    background: #edf4ff;
    color: #000;
}

.table_row:hover .table_cell.table_main{
    color: #7b61ff;
}
.table_cost {
    width:200px;
}

.table_row .table_cell .accept{
    color:#00a93c;
    border: 1px solid #baf7d0;
    background: #f6fdf9;
    padding:2px 8px;
    border-radius: 10px;
    display: inline-block;
}

.table_row .table_cell .reject{
    color:#f43f5e;
    border: 1px solid #fddbe0;
    background: #feecef;
    padding:2px 8px;
    border-radius: 10px;
    display: inline-block;
}

.table_row .table_cell .back{
    color:#7b61ff;
    border: 1px solid #d8d0ff;
    background: #f9f8ff;
    padding:2px 8px;
    border-radius: 10px;
    display: inline-block;
}

.table_row .table_cell .wait{
    color:#bda700;
    border: 1px solid #f5e889;
    background: #fbfdf6;
    padding:2px 8px;
    border-radius: 10px;
    display: inline-block;
}

.table_row .table_cell .progress {
    width:140px;
    height:11px;
    background:#e0e3e7;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom:4px;
}
.table_row .table_cell .progress .progress_act {
    height:11px;
    background:#00a93c;
    position: absolute;

}
.table_row .table_cell .progress .progress_report {
    height:11px;
    background:#fdcc04;
    position: absolute;
}

.table_cell .comment {
    max-width: 230px;
    margin-top:10px;
}
.progress_info_act {
    color:#00a93c;
    font-size:12px;
    line-height: 20px;
}
.progress_info_report {
    color:#bda700;
    font-size:12px;
    line-height: 20px;
}

.copy_all {
    border: 1px solid #7b61ff;
    cursor: pointer;
    color: #fff;
    background: #7b61ff;
    border-radius: 4px;
    padding: 5px 0;
    font-size: 12px;
    font-weight: bold;
    width: 100px;
    margin: 4px auto 0;
}
.copy_all:hover {
    border: 1px solid #7b61ff;
    background: #493999;
}

.copy_all.active {
    background: #00a93c;
    border: 1px solid #00a93c;
}
/*
.block:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.block .row{
    display: table;
    width: 100%;
}


.block .name, .block .info, .block .sum {
    display: table-cell; 
}

.block .info{
    padding-bottom:14px;
    line-height: 24px;
}
.block .name {
    width:500px;
}
.block .sum {
    width:200px;
    text-align: right;
    padding:10px;
    font-size: 14px;
}

.block .sum span  {
    color:#343434;
    padding-left:2px;
    font-size: 10px;
    font-weight: 300;
    padding-right: 10px;
}
.block .sum_header {
    color: #929295;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 12px;
    padding-right: 10px;
}

.block .sum .status_row {
  margin-bottom:10px;

}

.block .sum .status {
    text-transform: uppercase;
    background: #fff7e0;
    padding: 4px 10px;
    color: #c3941e;
    border-radius: 50px;
    display: inline-block;
    font-weight: 700;
    font-size:12px;

}
.block .organization a, .block .sub a{
    font-weight: 700;
    color:#4e73df;
    border-bottom:1px solid #c6cfe9;
}
.block .object, .block .contrestimate {
    font-size:14px;
    color:#707070;
}

.block .name {
    font-weight: bold;
    font-size: 24px;
    padding:22px 0 22px 28px;
}

.block .date {

    color:#343434;
    
    font-size: 14px;
    font-weight: 300;
}



.block .units_table {
    display: none;
    width: 200px;
}

.block .units_table span {
    padding-left:2px;
   font-size:12px;
}
.block .units .complete {
    color:#df4e4e;

}



.block .units .scope, .block .units .complete{
    display: table-cell;
    width: 50%;
    font-weight: 700;
}


.block .list tr:hover td{

    background: #f8f9fc;
}
.block .list tr.done .progress_state{
    background:#1cc88a;

}
.block .list tr.done .complete {
    color:#19a371;
}

.block .list tr.done .done_icon {
    display: block;
}



.block .progress {
    height:8px;
    width:100%;
    background: #e9ecef;
    border-radius: 8px;
    margin:4px 0;
    
}

.block .progress_state {
    height:8px;
    width:0%;
    background: #4e73df;
    border-radius: 8px;
}

.block .progress_info {
    color: #343434;
    font-size: 12px;
    font-weight: 300;
}

.block .units .scope {
    text-align: right;
}
*/
.block_table {
    overflow-y: auto;
    font-family: "Open Sans", sans-serif;
}

.block .list {
    width:100%;
    
    border-collapse: collapse;
    text-align: left;
    border-radius: 10px;

}



.block .done td{
    background: #f0fbf7;
}

.block .progress td{
    background: #fbfaf0;
}


.block .list tr, .block .list th, .block .list td  {
    border-bottom:1px solid #e0e3e7;
    border-right:1px solid #e0e3e7;

}

.block .list tr:last-child, .block .list th:last-child, .block .list td:last-child{
    border-right:1px solid transparent;
}

.block .list td:first-child {
    min-width: 400px;
}

.block .list tr[level="2"], .block .list tr[level="3"], .block .list tr[level="4"]{

}
/*
.block .list tr.section[level="2"] td:first-child {
    padding-left:40px;
}
.block .list tr.section[level="3"] td:first-child {
    padding-left:80px;
}

.block .list tr.section[level="4"] td:first-child {
    padding-left:120px;
}*/

.block .list tr.section td:first-child{
    font-weight: bold;
}


.block .list tr.section[level="1"] td{
    background: #dedaf3;
}
.block .list tr.section[level="2"] td{
    background: #eceafb;
}
.block .list tr.section[level="3"] td{
    background: #f9fafb;
}

.block .list .fade_yellow {
    background: #fffdfa !important;
}
.block .list tr:hover td{
    background: #f5f9ff !important;
}

.block .list tr:hover td.fade_yellow{
    background: #fff5dd !important;
}



.block .list tr th {
    color:#000;
    font-weight: 700;
  
    font-size:12px;
    text-align: center;

}

.block .list tr  {
    font-size:13px;
    line-height: 16px;

}
.block .list tr td, .block .list tr th {
    padding:10px 3px;
     vertical-align: top;
}

.block .money {
    min-width: 90px;
}

.block .head {
    width: 40px;
}

.block .list tr td:nth-child(2){
   min-width: 90px;
}

.block .list tr td:nth-child(2) span{




}


#progress .block .list tr td:nth-child(9), #progress .block .list tr td:nth-child(11){

   

}

.block .center {
    text-align: center;
    min-width: 90px;
}
.block .right {
    text-align: right;
    min-width: 90px;
}

.block .small{
    width: 28px;
}

.block .report_save {
    color:#bda700;
}

.block_table input{
    text-align: center;
    border-radius: 4px;

    width: 80px;
    box-sizing: border-box;
    line-height: 24px;
    border:1px solid #000;

}

.block_table input:focus {
    outline: none !important;
    border:1px solid #14b8a6;
}


.block_table .report {
    width: 120px;
}


.block_table .report.error input{
    border: 1px solid #f43f5e;
}

.block_table .report .text {
    color:#f43f5e;
    margin-top:4px;
    display: none;
}
.block_table .report.error .text {
    display: block;
}
.block_table .done {
    color:#00a93c;

}

.block_table .progress {
    background: #e0e3e7;
    height:10px;
    width: 100%;
    border-radius: 4px;
    overflow: hidden;
 
}

.block_table .progress div{
    background: #fdcc04;
    height:10px;
    border-radius: 0;
}

.block_table .progress[p="100"] div{
    background: #14b8a6;
}


.block_table .act_link {
    border:1px solid #dedcf6;
    border-radius: 10px;
    padding: 4px 2px;
    text-align: center;

    color:#5347ce;
    cursor: pointer;
    display: block;
}

.block_table .act_link:hover {
    background: #5347ce;
    border:1px solid #5347ce;
    color:#fff;
}

.block .button {
    border: 1px solid #7b61ff;
    cursor: pointer;
    color: #fff;
    background: #7b61ff;
    border-radius: 10px;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: bold;
    display: inline-block;
    margin:0 0 20px 20px;
}

.block .button.remove {

    border: 1px solid #f43f5e;
    background: #f43f5e;
    float: right;
    margin-right: 20px;
}

.block .button.back {
    background: #f43f5e;
    border: 1px solid #f43f5e;
}

.block .button.approve {
    background: #00a93c;
    border: 1px solid #00a93c;
}

.block .button.reject {
    background: #bda700;
    border: 1px solid #bda700;
}

.block .button:hover {
    border: 1px solid #7b61ff;
    background: #493999;
}


/*
.block .copy {
    vertical-align: top;
    background: #7b61ff;
    width: 18px;
    height: 18px;
    padding: 5px;
    border-radius: 4px;
    cursor: pointer;
    user-select: none;
}
*/
/*
.block .save {
    vertical-align: top;
    background: #000;
    width: 18px;
    height: 18px;
    padding: 5px;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
    user-select: none;
}*/
/*
.block .edit {
    vertical-align: top;
    background: #fdcc04;
    width: 18px;
    height: 18px;
    padding: 5px;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
    user-select: none;
    display: none;
}*/

.block .warning {
    vertical-align: top;
    background: #f43f5e;
    width: 18px;
    height: 18px;
    padding: 5px;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
    user-select: none;
    display: none;
}

.block .copy:hover {
    background: #16c8c7;
}


.card_row {
    display: flex;
    flex-direction: row;
}

.card {
    flex-grow: 1;
    width: 33%;
    background: #fff;
    border-radius: 10px;
    color: #000;
    margin-bottom: 20px;
    border: 1px solid #e0e3e7;
    cursor: pointer;
    margin: 12px;
    padding: 0 26px 26px 26px;
}

.card a{
    color: #493999;
    border-bottom: 1px solid #e1ddf3;
}

.card a:hover{
    border-bottom: 1px solid #493999;
}

.card.pt{
    padding: 22px 26px;
}
.card_header{
    font-weight: bold;
    font-size: 18px;
    margin: 18px 0 10px 0;
}
.card_header2 {
    font-weight: bold;
    font-size: 16px;
}
.card_date {
    font-size: 14px;
    line-height: 28px;
}

.card_link {
    font-size: 14px;
    line-height: 28px;
}

.card_money {
    text-align: right;
    font-size: 16px;
    line-height: 28px;
}

.card_table {
    display: table;
    line-height: 28px;
    width: 100%;
}

.card_attr {
    display: table-cell;
    font-size: 14px;
    width: 30%;
    vertical-align: top;
    
}

.card_value {
    display: table-cell;
    font-size: 16px;
    vertical-align: top;
}

.card_header.right {
    text-align: right;

}

.card_table {
    display: table;
}

.card_table_cell {
    display: table-cell;
    vertical-align: top;
}

.block .block_report {
    margin-top:20px;
}

.block .block_report .error {
    color: #f43f5e;
    font-weight: bold;
    line-height: 32px;
    margin: 0 20px 20px 20px;
    border: 1px solid #fddbe0;
    background: #feecef;
    border-radius: 10px;
    padding: 10px 20px;

}

.block .block_report .success {
    color: #00a93c;
    font-weight: bold;
    line-height: 32px;
    margin: 0 20px 20px 20px;
    border: 1px solid #baf7d0;
    background: #f6fdf9;
    border-radius: 10px;
    padding: 10px 20px;

}
.block .block_report .buttons {
 
}
.setting_info {
    font-size: 16px;
    line-height:40px;
    padding: 0 26px;
    color:#697386;
    font-weight: ;
}

.setting_value {
    padding: 0 20px 20px 20px;
}

.setting_value input[type="text"] {
    width: 200px;
    border: 1px solid #000;
    border-radius: 10px;
    padding: 0 20px 0 20px;
    box-sizing: border-box;
    border: 1px solid #e0e3e7;
    line-height: 38px;
    display: inline-block;
    vertical-align: top;
    font-family: "Open Sans", sans-serif;
}

#modal_body .setting_value input[type="text"] {
    width: 100%;
}
.setting_value input[type="date"] {
    width: 200px;
    border: 1px solid #000;
    border-radius: 10px;
    padding: 0 20px 0 48px;
    box-sizing: border-box;
    border: 1px solid #e0e3e7;
    line-height: 38px;
    display: inline-block;
    vertical-align: top;
    font-family: "Open Sans", sans-serif;
}
.setting_value textarea {
    width: 100%;
    height:100px;
    border: 1px solid #000;
    border-radius: 10px;
    padding: 10px 20px;
    box-sizing: border-box;
    border: 1px solid #e0e3e7;
    line-height: 20px;
    display: inline-block;
    vertical-align: top;
    font-family: "Open Sans", sans-serif;
}

.setting_value .separator {
     display: inline-block;
     line-height: 38px;
     width: 20px;
     text-align: center;
     font-size: 18px;
     color:#697386;
}
/*
.profile_edit input{
    width: 200px;

    border-radius: 10px 0 0 10px;
    padding:0 10px;
    box-sizing: border-box;
    border: 1px solid #e0e3e7;
    line-height: 30px;

    vertical-align: top;
    font-family: "Open Sans", sans-serif;
    cursor: pointer;
}

.profile_edit input:disabled {
    border: 1px solid transparent;
    background: transparent;
    color: #000;
    padding:0;
}

.profile_edit button{
    width: 100px;
    padding:0 10px;
    border-radius: 0 10px 10px 0;
    box-sizing: border-box;
    border: 1px solid #e0e3e7;
    line-height: 30px;
    background: #e0e3e7;
    vertical-align: top;
    font-family: "Open Sans", sans-serif;
    cursor: pointer;
}


.profile_edit button:hover {
    background: #f6fdf9;
    border: 1px solid #baf7d0;
    color:#00a93c;
}

.profile_edit .edit {
    border-radius: 10px;
}

.profile_edit .edit.active {
    border-radius: 0 10px 10px 0;
    color:#f43f5e;
    border: 1px solid #fddbe0;
    background: #feecef;
}

.profile_edit .save {
    border-radius: 0;
}

.profile_active button {
    width: 100px;

    border-radius: 10px;
    box-sizing: border-box;

    line-height: 30px;

    cursor: pointer;
    color:#f43f5e;
    border: 1px solid #fddbe0;
    background: #feecef;
    font-size:14px;
}

.profile_active button.active {
    background: #f6fdf9;
    border: 1px solid #baf7d0;
    color:#00a93c;
}
.profile_active button:hover {
    background: #fddbe0;
}

.profile_active button.active:hover {
    background: #baf7d0;
}
*/

.profile_more {
    
    border-radius: 10px;
    display: inline-block;
    cursor: pointer;

}
.profile_more:hover {
    background: #f9fafb;
}
.profile_more img {
    padding:8px;
    width: 18px;
    height: 18px;
    vertical-align: top;
    display: inline-block;
}
.profile_buttons {
    display: none;
    position: absolute;
    width:200px;
    background:#fff;
    border-radius: 10px;
    
    margin-left:-148px;
    border: 1px solid #e0e3e7;
    user-select: none;

}
.profile_buttons.active {
    display: block;
}
.profile_buttons .profile_button {
   text-align: left;
   cursor: pointer;
   padding:0 10px;
   line-height: 40px;
   border-bottom: 1px solid #e0e3e7;
}

.profile_buttons .profile_button:last-child{
    border-bottom: 0;
}
.profile_buttons .profile_button:hover {
    background: #f9fafb;
}

.profile_buttons .profile_button.remove {
    color:#f43f5e;
    background: #feecef;
}

.upload_files {
    margin:0 !important;
    background: none !important;
}
.upload_files .button {
    margin:0 0 10px 0;
    line-height: 18px;

}
.upload_files input {
    display: none;
}
.files {
    border: 1px solid #e0e3e7;
    border-radius: 10px;
    padding:10px 10px 0 10px;
    margin:0 20px 20px 20px;
    line-height: 30px;
}
.files .header {
    font-weight: bold;
    font-size: 16px;
    margin:0 0 6px 0;
    background: none;
}
.files .delete {
    
    cursor: pointer;
    color: #fff;
    background: #f43f5e;
    border-radius: 10px;
    
    font-size: 14px;
    font-weight: bold;
    display: inline-block;
    margin: 0;
    cursor: pointer;
    line-height: 14px;
    vertical-align: top;
}

.files .delete img {
    width: 14px;
    height: 14px;
    vertical-align: top;
    padding:8px;
}

.files .delete:hover {
    background: #c9304a;
}

.files .loading {
    padding:0 10px;
    color:#666;
}

.files .loading img {
    width: 30px;
    margin-right: 10px;
}
.files div{
    border-radius: 10px;
    background: #f9fafb;
    margin-bottom: 10px;
}
.files a {
    margin-left:10px;
    color: #493999;
}
.files a:hover {
    color: #00a93c;
}
/*MODAL*/
.modal-open {
    /*overflow: visible;*/
}
#modal {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    display: none;
}

.modal-open #modal {
    display: block;
}
#modal_block {
    max-width: 500px;
    margin: 3rem auto;
    background: #fff;
    border-radius: 10px;
}

#modal_header {
    line-height: 34px;
    font-size: 28px;
    font-weight: bold;
    padding:14px 20px;
    border-bottom: 1px solid #eaf0f5;
}
#modal_body {
    border-bottom: 1px solid #eaf0f5;
    padding:14px 20px;
}

#modal_bottom {
    text-align: right;
    padding:14px 20px;
}

#modal_bottom button{
    border: 1px solid #7b61ff;
    cursor: pointer;
    color: #fff;
    background: #7b61ff;
    border-radius: 10px;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: bold;
    display: inline-block;
    margin: 0 0 0 20px;
}
#modal_close {
    float: right;
    cursor: pointer;
    line-height: 10px;
    border-radius: 10px;
}
#modal_close img {
    width: 14px;
    height: 14px;
    padding:8px;
    vertical-align: top;
}
#modal_close:hover {
    background: #f9fafb;
}
/*LOADER*/
.circular-chart {
  display: block;
  margin: auto;
  width: 140px;
}

.circle-bg {
  fill: none;
  stroke: #e0e3e7;
  stroke-width: 2.8;
}

.circle, .circle-send {
  fill: none;
  stroke-width: 2.8;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
  stroke: #00a93c;
}

.circle-send {
  stroke: #fdcc04;
}

.percentage {
  fill: #00a93c;
  font-size: 6px;
  text-anchor: middle;
}
.percentage_report {
  fill: #bda700;
  font-size: 3px;
  text-anchor: middle;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}


/*Навигация*/
ul[role="navigation"] {
    list-style-type: none;
    padding:0;
    margin:0;
    user-select: none;
}
ul[role="navigation"] li{
    display: inline-block;
    background: #fff;
    border-radius: 10px;
    color: #000;
    border: 1px solid #e0e3e7;
    margin: 4px;
    
    cursor: pointer;
}
ul[role="navigation"] li:hover {
    color:#00a93c;
    background:#edf4ff;
}
ul[role="navigation"] li a{
    padding:12px;
    display: inline-block;
}
ul[role="navigation"] li.break {
     background:none;
     border:none;
}

ul[role="navigation"] li.selected {
     background:none;
     color: #493999;
}
/*
.block .buttons {
    text-align: right;
    padding:10px;
}

.block .buttons a  {
    color:#fff;
    background: #4e73df;
    display: inline-block;
    border-radius: 6px;
    padding:10px 20px;
}

.block .list .history {
    display:table;
    width: 100%;
    min-width: 200px;
}

.block .history .date, .block .history .work {
    display:table-cell;
    width: 50%;
}

.block .history .date {
    color:#666;
    font-size: 12px
}
.block .history .work {
    text-align: right;
}

.block_hide  {
 
    overflow-y: auto;
}

.block .estimate, .block .acts {
    white-space: nowrap;

}

.page_block {
    border-radius: 6px;
    border:1px solid #ccc;
    padding:10px;
    background: #fff;
    margin-bottom:20px;
}


.table_block {
    display: table;
    width: 100%;
    color:#000;
    border-collapse: collapse;
}

.table_row {
    display: table-row;
    border-bottom: 1px solid #ccc;

}

.table_row:last-child {
    border-bottom:0;
}

.table_header {
    font-weight: bold;
}

.table_block .id, .table_block .name, .table_block .inn, .table_block .user, .table_block .buttons  {
    display: table-cell;
    vertical-align: top;
    padding:10px 20px;
    line-height: 20px;
}

.table_block .buttons {
    text-align: right;
}
.table_block .buttons button{
    margin-left:10px;
    border: none;
    cursor: pointer;
    color: #fff;
    background: #4e73df;
    border-radius: 6px;
    padding: 10px 20px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
}

.table_block .buttons button:hover{
    background: #000;
}
.table_block .id {
    width: 80px;
    text-align: right;
    font-weight: bold;
}

.table_block a{
    font-weight: 700;
    color: #4e73df;
    border-bottom: 1px solid #c6cfe9;
}

.table_block a:hover {
    border-bottom: 1px solid #4e73df;
}

.pagination {
    text-align: center;
}

.pagination .page_link{
    background: #fff;
    border-left:1px solid #ccc;
    border-top:1px solid #ccc;
    border-bottom:1px solid #ccc;
    display: inline-block;
    color:#000;
    text-align: center;
    line-height: 40px;
    padding: 0 20px;
}
.pagination .page_link:hover {
    background: #eee;
}
.pagination .page_space{
    background: #fff;
    border-left:1px solid #ccc;
    border-top:1px solid #ccc;
    border-bottom:1px solid #ccc;
    display: inline-block;
    color:#ccc;
    text-align: center;
    line-height: 40px;
    padding: 0 20px;
}

.pagination .page_link:first-child{
    border-radius: 4px 0 0 4px;
}

.pagination .page_link:last-child{
    border-right:1px solid #ccc;
    border-radius: 0 4px 4px 0;
}

.pagination .page_link.active{
    color:#4e73df;
    font-weight: bold;
}

.block_header {
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 10px;
}

.settings_name {
    line-height: 30px;
}

.settings_value {
    line-height: 30px;
}

.settings_value input {
    border: 2px solid #4e73df;
    cursor: pointer;
    color: #000;
    background: #fff;
    border-radius: 6px;
    padding: 10px 20px;
    font-size: 14px;
}

.settings_button {
    padding:10px 0;
}

.settings_button button {
    border: none;
    cursor: pointer;
    color: #fff;
    background: #4e73df;
    border-radius: 6px;
    padding: 10px 20px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
}
*/

@keyframes blockPlaceholder {
    0%   { background-position: 0 0; }
  100% { background-position: -200% 0; }

 
}

.text_placeholder {
    animation-duration: 1.8s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #fafafa 8%, #f4f4f4 38%, #fafafa 54%);
    background-size: 1000px 640px;
    display: inline-block;
    border-radius: 10px;
    height:16px;
    width:100%;
    vertical-align: top;
    margin: 11px 0;
}

@keyframes placeHolderShimmer{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}


/*LOADER*/
.loader {
    text-align: center;
    margin-bottom: 20px;
}
.loader_row {
  /* change color here */
  color: #7b61ff
}
.loader_row,
.loader_row div {
  box-sizing: border-box;
}
.loader_row {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.loader_row div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid currentColor;
  border-radius: 50%;
  animation: loader_rotating 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: currentColor transparent transparent transparent;
}
.loader_row div:nth-child(1) {
  animation-delay: -0.45s;
}
.loader_row div:nth-child(2) {
  animation-delay: -0.3s;
}
.loader_row div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes loader_rotating {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}